import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Spacer from "../components/spacer"
import TitleAndText from "../components/title-and-text"
import ImageAndText from "../components/image-and-text"
import BulletList from "../components/bullet-list"
import Bullet from "../components/bullet"
import CenteredSlider from "../components/centered-slider"
import CenteredSlide from "../components/centered-slide"
import WorkGrid from "../components/work-grid"
import ImageGridItemService from "../components/image-grid-item-service"
import Testimonials from "../components/testimonials"

class AboutPage extends Component {
  render() {
    const data = this.props.data

    return (
      <Layout background={ true } >
        <SEO title="Services" />
        <Hero 
          image="hero.jpg"
          title="Services"
          subtitle="We offer a range of services and have a diverse skillset in all things digital to help you achieve your goals and improve your online presence."
        />

        <WorkGrid>
          <ImageGridItemService width={6} image={ '../web-development.jpg' } subtitle="Development" title="Website Development" link="/services/website-development-bournemouth" />
          <ImageGridItemService width={6} image={ '../web-design.jpg' } subtitle="Design" title="Website Design" link="/services/website-design-bournemouth" />
          <ImageGridItemService width={6} image={ '../wordpress-service.jpg' } subtitle="WordPress" title="WordPress Development" link="/services/wordpress-development-bournemouth" />
          <ImageGridItemService width={6} image={ '../shopify-service.jpg' } subtitle="Shopify" title="Shopify Development" link="/services/shopify-development-bournemouth" />
          <ImageGridItemService width={6} image={ '../craft-cms.jpg' } subtitle="Craft CMS" title="Craft CMS Development" link="/services/craft-cms-bournemouth" />
          <ImageGridItemService width={6} image={ '../wordpress-services.jpg' } subtitle="WordPress" title="WordPress Maintenance & Support" link="/services/wordpress-maintenance" />
          <ImageGridItemService width={6} image={ '../email.jpg' } subtitle="Development" title="Email Templates" link="/services/email-templates" />
          <ImageGridItemService width={6} image={ '../maintenance.jpg' } subtitle="Development" title="Website Maintenance" link="/services/website-maintenance" />
          <ImageGridItemService width={6} image={ '../security.jpg' } subtitle="Development" title="Website Security" link="/services/website-security" />
          <ImageGridItemService width={6} image={ '../speed.jpg' } subtitle="Development" title="Website Optimisation" link="/services/website-optimisation" />
        </WorkGrid>     

        <Spacer space="4" />   
        <Testimonials />
        <Spacer space="4" />   
        <CenteredSlider>
          {data.allWordpressPost.edges.map(({ node }) => (
              <CenteredSlide key={node.id} title={node.title} image={ node.featured_media.localFile.childImageSharp.original.src } link={node.path} />
          ))}
        </CenteredSlider> 
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          id
          slug
          path
          title
          featured_media {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }        
        }
      }
    }
  }
`